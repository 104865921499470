








import { Component, Vue } from "vue-property-decorator";
import OfertasOmil from "@/components/Web/OfertasOmil.vue";

@Component({
  components: {
    OfertasOmil
  },
  meta: {
    // sets document title
    title: "Bolsa de Empleo - Municipalidad de Arica",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Revisa aquí las Bolsa de Empleo OMIL disponibles para ti."
      },
      keywords: { name: "keywords", content: "Arica" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class OfertasOmilView extends Vue {
  private mounted() {
    document.title = "Bolsa de Empleo - Municipalidad de Arica";

    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://muniarica.cl/municipio/ofertas";
    document.head.appendChild(canonicalLink);
  }
}
