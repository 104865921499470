import { Component, Vue } from "vue-property-decorator";
declare module "vue/types/vue";

@Component
export default class FormValidator extends Vue {
  protected formData: any;
  formIsRequired(value: string) {
    if (value) {
      return true;
    }
    return "Este campo es obligatorio";
  }
  formSelectIsRequired(value: any) {
    if (value.value || value.value == 0) {
      return true;
    }
    return "Este campo es obligatorio";
  }
  formPhoneNumber(value: number) {
    if (value && value > 99999999) {
      return true;
    }
    return "Debe ingresar un teléfono válido";
  }
  formIsEmail(value: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(value) === true) {
      return true;
    }
    return "Debe ingresar un correo válido";
  }
  formPasswordCofirm(value: string) {
    if (this.formData.password === value) {
      return true;
    }
    return "Las contraseñas no coinciden";
  }
  formNewPasswordCofirm(value: string) {
    if (this.formData.plainPassword === value) {
      return true;
    }
    return "Las contraseñas ingresadas no coinciden";
  }
  formPasswordNewCofirm(value: string) {
    if (this.formData.repeatPass === value) {
      return true;
    }
    return "Las contraseñas ingresadas no coinciden";
  }
  formPasswordRegistroCofirm(value: string) {
    if (this.formData.confirmPass === value) {
      return true;
    }
    return "Las contraseñas ingresadas no coinciden";
  }
  formIsPassword(value: string) {
    if (value.length >= 6) {
      return true;
    }
    return "Debe tener almenos 6 caracteres";
  }
  formValidRut(value: string) {
    if (this.validateRut(value)) {
      return true;
    }
    return "Debe ingresar un rut válido";
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
