


































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Boletines extends Vue {
  private nombreWeb: any = "";
  private boletines: any = [];
  private currentPage = 1;
  private total = 0;
  private mostrar = false;

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 12)) {
      this.getInfoBoletines();
    }
  }

  private mounted() {
    this.getInfoBoletines();
  }

  private getInfoBoletines() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("boletines?pagina=" + this.currentPage)
      .then((res: any) => {
        console.log(res);
        if (res.data.boletines.length == 0) {
          this.mostrar = true;
        }
        // console.log("docu", res.data);
        this.boletines = res.data.boletines;
        for (let i = 0; i < this.boletines.length; i++) {
          if (this.boletines[i].foto) {
            this.boletines[i].foto =
              "https://docs.muniarica.cl/" + this.boletines[i].foto;
          } else {
            this.boletines[i].foto =
              "https://docs.muniarica.cl/Noticias/default_preview.jpg";
          }
        }
        this.total = res.data.total;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
}
