













































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import FormValidator from "@/components/mixins/FormValidator.ts";
import { Notify } from "quasar";
import { Debounce } from "vue-debounce-decorator";
interface dataItem {
  id: number | any;
  nombre: string | any;
}
interface dataFilter {
  categorias: Array<dataItem>;
  fechas: number | any;
  texto: string | any;
}
interface dataOfertas {
  codigo: string | any;
  descripcion: string | any;
  estado: number | any;
  fecha_publicacion: string | any;
  fecha_vigencia: string | any;
  id_categoria: number | any;
  id: number | any;
  nombre: string | any;
  direccion: string | any;
  empresa: string | any;
  generico: number | any;
  boton: number | any;
  pregunta_uno: string | any;
  pregunta_dos: string | any;
}
@Component
export default class OfertasOmil extends Mixins(FormValidator) {
  private win = window;
  private drawer = false;
  private postular = false;
  private tab = "";
  private expanded = false;
  private expandedFecha = false;
  private dataDialog = false;
  private textoBuscar = "";
  private categoriasOmil: Array<any> = [];
  private formattedRut: string = "";
  private invalidFile = false;
  private curriculum: File | null = null;
  private ofertas: Array<any> = [];
  private dataBuscar: dataFilter = {
    categorias: [],
    fechas: 0,
    texto: ""
  };
  private ofertasData: dataOfertas = {
    codigo: "",
    descripcion: "",
    estado: null,
    fecha_publicacion: "",
    fecha_vigencia: "",
    id_categoria: null,
    id: null,
    nombre: "",
    direccion: null,
    empresa: null,
    generico: null,
    boton: null,
    pregunta_uno: null,
    pregunta_dos: null
  };

  private data: any = {
    nombre: "",
    rut: "",
    email: "",
    telefono: "",
    direccion: "",
    respuesta_uno: "",
    respuesta_dos: ""
  };

  private fechas = [
    {
      id: 0,
      label: "Todos",
      seleccionado: false
    },
    {
      id: 1,
      label: "Día anterior",
      seleccionado: false
    },
    {
      id: 2,
      label: "Los últimos 3 días",
      seleccionado: false
    },
    {
      id: 3,
      label: "Última semana",
      seleccionado: false
    },
    {
      id: 4,
      label: "Último mes",
      seleccionado: false
    }
  ];

  private lastFiltro = "";
  private currentPage = 1;
  private total = 0;
  @Watch("textoBuscar")
  private selectTextoBuscar(newValue: any) {
    this.dataBuscar.texto = newValue;
  }
  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "");
    this.data.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  private mounted() {
    this.getCategorias();
    this.buscar();
  }

  private getCategorias() {
    this.$axios
      .get("categorias")
      .then(res => {
        const responseData = res.data;
        responseData.forEach((categoria: any) => {
          if (categoria.id > 0) {
            this.categoriasOmil.push({
              id: categoria.id,
              nombre: categoria.nombre,
              seleccionado: false
            });
          }
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private vaciarInput() {
    this.textoBuscar = "";
    this.buscar();
  }
  private categoria() {
    if (this.tab == "") {
      this.expanded = true;
    }
    if (this.tab == "fecha") {
      this.expanded = true;
    }

    if (this.tab == "categorias") {
      this.expanded = false;
      this.tab = "";
    }
  }
  private fecha() {
    if (this.tab == "") {
      this.expandedFecha = true;
    }
    if (this.tab == "categorias") {
      this.expandedFecha = true;
    }

    if (this.tab == "fecha") {
      this.expandedFecha = false;
      this.tab = "";
    }
  }

  private mostrarDatosDesktop(id_ofertas: any) {
    for (let i = 0; i < this.ofertas.length; i++) {
      if (this.ofertas[i].id == id_ofertas) {
        this.ofertasData.codigo = this.ofertas[i].codigo;
        this.ofertasData.descripcion = this.ofertas[i].descripcion;
        this.ofertasData.estado = this.ofertas[i].estado;
        this.ofertasData.fecha_publicacion = this.ofertas[i].fecha_publicacion;
        this.ofertasData.fecha_vigencia = this.ofertas[i].fecha_vigencia;
        this.ofertasData.id_categoria = this.ofertas[i].id_categoria;
        this.ofertasData.id = this.ofertas[i].id;
        this.ofertasData.nombre = this.ofertas[i].nombre;
        this.ofertasData.direccion = this.ofertas[i].direccion;
        this.ofertasData.empresa = this.ofertas[i].empresa;
        this.ofertasData.generico = this.ofertas[i].generico;
        if (this.ofertas[i].boton) {
          this.ofertasData.boton = this.ofertas[i].boton;
        } else {
          this.ofertasData.boton = 0;
        }
        if (this.ofertas[i].pregunta_uno) {
          this.ofertasData.pregunta_uno = this.ofertas[i].pregunta_uno;
        } else {
          this.ofertasData.pregunta_uno = null;
        }
        if (this.ofertas[i].pregunta_dos) {
          this.ofertasData.pregunta_dos = this.ofertas[i].pregunta_dos;
        } else {
          this.ofertasData.pregunta_dos = null;
        }
      }
    }
  }

  private mostrarDatosMobile(id_ofertas: any) {
    for (let i = 0; i < this.ofertas.length; i++) {
      if (this.ofertas[i].id == id_ofertas) {
        this.ofertasData.codigo = this.ofertas[i].codigo;
        this.ofertasData.descripcion = this.ofertas[i].descripcion;
        this.ofertasData.estado = this.ofertas[i].estado;
        this.ofertasData.fecha_publicacion = this.ofertas[i].fecha_publicacion;
        this.ofertasData.fecha_vigencia = this.ofertas[i].fecha_vigencia;
        this.ofertasData.id_categoria = this.ofertas[i].id_categoria;
        this.ofertasData.id = this.ofertas[i].id;
        this.ofertasData.nombre = this.ofertas[i].nombre;
        this.ofertasData.direccion = this.ofertas[i].direccion;
        this.ofertasData.empresa = this.ofertas[i].empresa;
        this.ofertasData.generico = this.ofertas[i].generico;
        if (this.ofertas[i].boton) {
          this.ofertasData.boton = this.ofertas[i].boton;
        } else {
          this.ofertasData.boton = 0;
        }
        if (this.ofertas[i].pregunta_uno) {
          this.ofertasData.pregunta_uno = this.ofertas[i].pregunta_uno;
        } else {
          this.ofertasData.pregunta_uno = null;
        }
        if (this.ofertas[i].pregunta_dos) {
          this.ofertasData.pregunta_dos = this.ofertas[i].pregunta_dos;
        } else {
          this.ofertasData.pregunta_dos = null;
        }
        this.dataDialog = true;
      }
    }
  }

  private seleccionCategoria(id_categoria: any) {
    for (let i = 0; i < this.categoriasOmil.length; i++) {
      if (this.categoriasOmil[i].id == id_categoria) {
        this.categoriasOmil[i].seleccionado = true;
        this.expanded = false;
        this.tab = "";
        this.dataBuscar.categorias.push({
          id: this.categoriasOmil[i].id,
          nombre: this.categoriasOmil[i].nombre
        });
      }
    }
    this.buscar();
  }
  private deseleccionarCategoria(id_categoria: any) {
    for (let i = 0; i < this.categoriasOmil.length; i++) {
      if (this.categoriasOmil[i].id == id_categoria) {
        this.categoriasOmil[i].seleccionado = false;
      }
      for (let j = 0; j < this.dataBuscar.categorias.length; j++) {
        if (this.dataBuscar.categorias[j].id == id_categoria) {
          this.dataBuscar.categorias.splice(j, 1);
          this.expanded = false;
          this.tab = "";
        }
      }
    }
    this.buscar();
  }

  private seleccionFecha(id_fecha: any) {
    for (let i = 0; i < this.fechas.length; i++) {
      if (this.fechas[i].id == id_fecha) {
        this.fechas[i].seleccionado = true;
        this.dataBuscar.fechas = id_fecha;
        this.expandedFecha = false;
        this.tab = "";
      } else {
        this.fechas[i].seleccionado = false;
      }
    }
    this.buscar();
  }
  private deseleccionarFecha(id_fecha: any) {
    for (let i = 0; i < this.fechas.length; i++) {
      if (this.fechas[i].id == id_fecha) {
        this.fechas[i].seleccionado = false;
        this.dataBuscar.fechas = 0;
        this.expanded = false;
        this.tab = "";
      }
    }

    this.buscar();
  }

  @Debounce(500)
  private buscar() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    var path = "";
    for (let i = 0; i < this.dataBuscar.categorias.length; i++) {
      if (i == 0) {
        path = path + "?categorias[]=" + this.dataBuscar.categorias[i].id;
      } else {
        path = path + "&categorias[]=" + this.dataBuscar.categorias[i].id;
      }
    }

    if (this.dataBuscar.fechas != null) {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }

      path = path + "fechas=" + this.dataBuscar.fechas;
    }

    if (this.dataBuscar.texto != "") {
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }

      path = path + "texto=" + this.dataBuscar.texto;
    }

    this.$axios
      .get("buscar" + path)
      .then((res: any) => {
        this.ofertas = res.data.ofertas;
        this.mostrarDatosDesktop(this.ofertas[0].id);
        this.textoBuscar = "";
        if (this.$route.query.cod && this.$route.query.cod != "") {
          for (let i = 0; i < this.ofertas.length; i++) {
            if (this.ofertas[i].id == this.$route.query.cod) {
              if (this.$q.screen.xs || this.$q.screen.sm) {
                this.mostrarDatosMobile(this.ofertas[i].id);
              } else {
                this.mostrarDatosDesktop(this.ofertas[i].id);
              }
            }
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
      });
  }

  private formatFecha(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  private checkFile(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 2 * 1024 * 1024 || !flag) {
        this.invalidFile = true;
        this.$swal({
          icon: "error",
          title: "Postulación",
          text:
            "El archivo adjunto excede el tamaño máximo (2 mb). Por favor, inténtelo de nuevo.",
          confirmButtonText: "Aceptar"
        });
      } else if (target.files[0].size <= 0 || !flag) {
        this.invalidFile = true;
      } else {
        if (target.name === "curriculum") {
          this.curriculum = target.files[0];
        }
        this.invalidFile = false;
      }
    }
  }

  private cerrarData() {
    this.postular = false;
    this.data.rut = "";
    this.data.nombre = "";
    this.data.email = "";
    this.data.telefono = null;
    this.data.direccion = "";
    this.curriculum = null;
    this.formattedRut = "";
    this.data.respuesta_uno = "";
    this.data.respuesta_dos = "";
  }

  private enviarPostulacion() {
    if (this.data.rut == "" || this.validateRut(this.data.rut) == false) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.data.nombre == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su nombre.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.data.email == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.data.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.data.telefono == null) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.telefono < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.data.direccion == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }

    if (this.curriculum == null) {
      Notify.create({
        color: "negative",
        message: "Por favor, adjunte un archivo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }

    const data = new FormData();
    if (this.curriculum) {
      const ciFile = this.curriculum as Blob;
      data.append("curriculum", ciFile);
      data.append("id_oferta", this.ofertasData.id);
    } else {
      const ciFile = new File(["file"], "Cv") as Blob;
      data.append("curriculum", ciFile);
      data.append("id_oferta", this.ofertasData.id);
    }

    for (const prop in this.data) {
      if (this.data.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.data[prop] || this.data[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.data[prop]);
        }
      }
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .post("postulacion", data, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(result => {
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente su postulación.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.$q.loading.hide();
        this.cerrarData();
        return;
      })
      .catch(error => {
        if (error.response.data.message == "RUT_INGRESADO") {
          this.$swal({
            icon: "error",
            title: "Error",
            text: "El rut ya fue ingresado.",
            confirmButtonText: "Aceptar"
          });
          this.cerrarData();
        }
        this.cerrarData();
        this.$q.loading.hide();
      });
  }

  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }

  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
